import React from "react";
import {
    Table, Modal, Button, Dropdown, Icon, Menu, Upload, Row, Col, message
} from "antd";
import { withRouter } from "react-router";
import TablePage from "../../components/TablePage";
import $http from "../../common/fetch";
import { artcleUrl } from '../../common/url'

let categoryMap = {
    1: '广告',
    2: '杂谈',
    3: '夜谭',
    4: '百妖'
}
let statusMap = {
    1: '已发布',
    2: '草稿'
}

let searchFormConfig = {
    form: [
        {
            title: '时间',
            id: 'time',
            type: 'range',
            format: 'yyyy-MM-DD',
            formatSub: {
                start: 'begin',
                end: 'end'
            }
        },
        {
            title: '作者',
            id: 'auther',
            type: 'select',
            data: artcleUrl.authorList,
            format: function (resp) {
                return resp.data.map(item => {
                    return {
                        code: item,
                        name: item
                    }
                })
            }
        },
        {
            title: '分类',
            id: 'category',
            type: 'select',
            data: [
                {
                    code: 1,
                    name: '广告'
                },
                {
                    code: 2,
                    name: '杂谈'
                },
                {
                    code: 3,
                    name: '夜谭'
                },
                {
                    code: 4,
                    name: '百妖'
                }
            ]
        },
        {
            title: '状态',
            id: 'status',
            type: 'select',
            data: [
                {
                    code: 0,
                    name: '全部'
                },
                {
                    code: 1,
                    name: '已发布'
                },
                {
                    code: 2,
                    name: '草稿'
                }
            ]
        },
        {
            title: '标题',
            id: 'title',
            type: 'text',
        },
        {
            title: '书名',
            id: 'book_name',
            type: 'text',
        }
    ]
}


class AllArticle extends React.Component {
    state = {

    }

    statusChange = (lineData) => {
        new Promise((resovle) => {
            if (lineData.status === 1) {
                Modal.confirm({
                    icon: <Icon type="warning" />,
                    title: `你正在下架 ${lineData.title}`,
                    content: '下架后前端不再展示，你还要继续吗？',
                    onOk: () => {
                        resovle(2)
                    }
                })
            } else {
                resovle(1)
            }
        }).then((status) => {
            $http.postData(artcleUrl.toggle, { id: lineData.id, status }).then(data => {
                message.success('成功');
                this.TablePage.getServerData();
            })
        })
    }

    add = () => {
        let { history } = this.props;
        window.open(window.location.origin + `/#/main/article/`);
        // history.push('/main/article/')
    }
    edit = (lineData) => {
        let { history } = this.props;
        window.open(window.location.origin + `/#/main/article/${lineData.id}?category=${lineData.category}`);
        // history.push(`/main/article/${lineData.id}?category=${lineData.category}`)
    }
    remove = (id) => {
        Modal.confirm({
            icon: <Icon type="warning" />,
            content: '确定删除文章吗',
            onOk: () => {
                $http.postData(artcleUrl.remove, { id }).then(data => {
                    message.success('成功');
                    this.TablePage.getServerData();
                })
            }
        })
    }
    render() {
        let authList = localStorage.getItem('zhiyao-auth-list') || [];
        let headColumns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: '标题',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (text, record) => {
                    return statusMap[record.status]
                }
            },
            {
                title: '作者',
                dataIndex: 'author',
                key: 'author',
            },
            {
                title: '分类',
                dataIndex: 'category',
                key: 'category',
                render: (text, record) => {
                    return categoryMap[record.category]
                }
            },
            {
                title: '最近更新日期',
                dataIndex: 'updated_at',
                key: 'updated_at',
            },
            {
                title: '操作',
                dataIndex: 'operation',
                render: (text, record) => {
                    return (
                        <React.Fragment>
                            {
                                authList.includes('article.toggle') &&
                                (record.status !== 1 ?
                                    <Button onClick={() => { this.statusChange(record) }}>上架</Button>
                                    :
                                    <Button onClick={() => { this.statusChange(record) }}>下架</Button>)
                            }
                            {authList.includes('article.save') ?
                                <React.Fragment>
                                    <Button style={{ marginLeft: '10px' }} onClick={() => { this.edit(record) }} >编辑</Button>
                                    <Button style={{ marginLeft: '10px' }} type="danger" danger onClick={this.remove.bind(this, record.id)}>删除</Button>
                                </React.Fragment> : null
                            }


                        </React.Fragment>
                    )
                }
            }
        ]
        let activeList = [
            {
                auth: 'article.save',
                title: '新增',
                click: this.add
            }
        ]
        return (
            <div className="page-user-list">
                <TablePage
                    ref={node => (this.TablePage = node)}
                    pageLine={['用户管理', '所有文章']}
                    activeList={activeList}
                    headColumns={headColumns}
                    loadStore={artcleUrl.list}
                    searchFormConfig={searchFormConfig}
                ></TablePage>
            </div>
        );
    }
}

export default withRouter(AllArticle);
