import React from "react";
import { Form, Input, Icon, Row, Col, Button } from "antd";
// 请求方法
import $http from "../../common/fetch";
//导入请求路径
import { loginUrl, commonUrl } from "../../common/url";
//导入css
import "./index.css";
import homebg from "../../assets/image/homeBG.jpg";
const FormItem = Form.Item;

class Login extends React.Component {
    constructor() {
        super();
    }
    componentDidMount() {

    }
    handleSubmit = e => {
        e.preventDefault();
        const { validateFieldsAndScroll } = this.props.form;
        validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                $http.postData(loginUrl.login, values).then(data => {
                    localStorage.setItem('zhiyao-token', data.data.token)
                    this.getPrivileges();
                })
            } else {
                console.log(err);
            }
        });
    };
    getPrivileges() {
        let { history } = this.props;

        $http.postData(loginUrl.privileges).then(data => {
            // 全部的权限都不会给， 自己加
            data.data.push({
                key: 'all',
                name: '全部'
            })
            localStorage.setItem('zhiyao-auth-list', data.data.map(item => item.key))
            history.push('./main')

        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div
                className="login-view"
                style={{
                    backgroundImage: `url(${homebg})`
                }}
            >
                <div className="header">
                    <div className="container">
                        {/* <img src={`${loginUrl.logo}?${this.logoRandom}`} alt="" /> */}
                    </div>
                </div>
                <div className="body">
                    <div className="login">
                        <h1 className="login-header">
                            <span>知妖</span>
                        </h1>
                        <div style={{ padding: "0 1rem" }}>
                            <div className="describe">请登录你的账号</div>
                            <Form onSubmit={this.handleSubmit} className="login-form">
                                <FormItem>
                                    {getFieldDecorator("account", {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: "请输入账号!"
                                            }
                                        ]
                                    })(
                                        <Input
                                            className="login-input"
                                            prefix={
                                                <Icon
                                                    type="user"
                                                    style={{
                                                        color: "rgba(0,0,0,.25)"
                                                    }}
                                                />
                                            }
                                            placeholder="账号"
                                        />
                                    )}
                                </FormItem>
                                <FormItem>
                                    {getFieldDecorator("password", {
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: "请输入密码"
                                            }
                                        ]
                                    })(
                                        <Input
                                            className="login-input"
                                            prefix={
                                                <Icon
                                                    type="lock"
                                                    style={{
                                                        color: "rgba(0,0,0,.25)"
                                                    }}
                                                />
                                            }
                                            type="password"
                                            placeholder="密码"
                                        />
                                    )}
                                </FormItem>
                                <FormItem>
                                    <Button type="primary" htmlType="submit" className="login-form-button">
                                        登录
                                    </Button>
                                </FormItem>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Form.create()(Login);
