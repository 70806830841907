import React from "react";
import { Table, Modal, Button, Dropdown, Icon, Menu, Upload, Row, Col, message } from "antd";
import TablePage from "../../components/TablePage";
import $http from "../../common/fetch";
import { userUrl } from '../../common/url'

let searchFormConfig = {
    form: [
        {
            title: '标题',
            id: 'title',
            type: 'text',
        },
        {
            title: '关键词',
            id: 'key',
            type: 'text',
        }
    ]
}

class SystemInfo extends React.Component {
    state = {
        handleOk: (...res) => {
            this.saveMessage(...res)
        }
    }

    lookDetail = (lineData) => {
        Modal.info({
            width: 1000,
            title: `查看`,
            content: (
                <React.Fragment>
                    <Row gutter={24}>
                        {lineData.title}
                    </Row>
                    <Row gutter={24}>
                        {lineData.created_at}
                    </Row>
                    <Row gutter={24}>
                        {lineData.content}
                    </Row>
                </React.Fragment>
            )
        })
    }
    addMessage = () => {
        this.TablePage.modalEdit(
            this.initAddessageConfig()
        );
    }
    saveMessage = (params, model) => {
        if (params){
            $http.postData(userUrl.addSysMess, params).then(data => {
                message.success('成功');
                model.close();
                this.TablePage.getServerData();
            })
        }
        
    }
    initAddessageConfig = () => {
        return {
            title: "新增",
            width: 800,
            data: [
                {
                    col: 24,
                    id: 'title',
                    label: '标题',
                    rules: [{ required: true, message: '必填' }],
                    type: 'text',
                },
                {
                    col: 24,
                    id: 'content',
                    label: '正文',
                    rules: [{ required: true, message: '必填' }],
                    type: 'richText',
                }
            ]
        }
    }
    render() {
        let authList = localStorage.getItem('zhiyao-auth-list') || [];
        let { handleOk } = this.state;
        let headColumns = [
            {
                title: '标题',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: '内容',
                dataIndex: 'content',
                key: 'content',
                render: (text, record) => {
                    return record.content.length > 10 ? record.content.slice(0, 10) + '...' : record.content
                }
            },
            {
                title: '创建人',
                dataIndex: 'creator',
                key: 'creator',
            },
            {
                title: '发送时间',
                dataIndex: 'created_at',
                key: 'created_at',
            },
            {
                title: '操作',
                dataIndex: 'operation',
                render: (text, record) => {
                    return (
                        <React.Fragment>
                            <Button onClick={() => { this.lookDetail(record)}}>查看</Button>
                        </React.Fragment>
                    )
                }
            }
        ]
        let activeList = [
            {
                auth: 'message.save',
                title: '新增',
                click: this.addMessage
            }
        ]
        return (
            <div className="page-user-list">
                <TablePage 
                    ref={node => (this.TablePage = node)}
                    pageLine={['用户管理', '系统消息']}
                    headColumns={headColumns}
                    activeList={activeList}
                    handleOk={handleOk.bind(this)}
                    loadStore={userUrl.messageSearch}
                    searchFormConfig={searchFormConfig}
                ></TablePage>
            </div>
        );
    }
}

export default SystemInfo;
