import React from "react";
import {
    Table, Select, Modal, Button, Dropdown, Icon, Menu, Upload, Row, Col, Input, Checkbox, Form, message
} from "antd";
import { withRouter, Prompt } from "react-router";
import MyForm, { RichText } from "../../components/MyForm";
import $http from "../../common/fetch";
import { artcleUrl, commonUrl } from '../../common/url'
import './index.css'
const qs = require("qs");
const { Option } = Select;


let getToken = () => {
    return localStorage.getItem("zhiyao-token") || undefined
}

let TextArea = Input.TextArea
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 3 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 }
    }
};

class Book extends React.Component {
    state = {
        books: [{ keyTime: (new Date()).getTime() }]
    }
    add() {
        let { change, value = [] } = this.props;
        value.unshift({ keyTime: (new Date()).getTime() });

        change(value)
    }
    del(index) {
        let { change, value = [] } = this.props;
        value.splice(index, 1);

        change(value)
    }
    change(key, changeValue, index) {
        let { change, value = [] } = this.props;
        if(changeValue == '<p></p>'){
            changeValue = null
        }
        value[index][key] = changeValue;

        change(value)

    }
    render() {
        let { value = [] } = this.props;
        return (
            <div className="add-article-book">
                <div className="add" onClick={this.add.bind(this)}>+新增典籍</div>
                {
                    value.map((item, index) => {
                        return (
                            <div key={item.keyTime || item.id} className="book-item">
                                <Row>
                                    <Col span={2}>
                                        <div className="label">书名:</div>
                                    </Col>
                                    <Col span={10}><Input value={item.name} onChange={(e) => { this.change('name', e.target.value, index) }} /></Col>
                                    <Col span={12}>
                                        <div className="del">
                                            <text onClick={() => {
                                                this.del(index)
                                            }}>删除</text>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={2}><div className="label">原文:</div></Col>
                                    <Col span={10}>
                                        <RichText
                                            value={item.origin}
                                            change={(value) => {
                                                this.change('origin', value, index)
                                            }}
                                        />
                                        {/* <TextArea value={item.origin} onChange={(e) => { this.change('origin', e.target.value, index) }} autoSize={{ minRows: 20, maxRows: 20 }} /> */}
                                    </Col>
                                    <Col span={2}><div className="label">译文:</div></Col>
                                    <Col span={10}>
                                        <RichText
                                            value={item.translate}
                                            change={(value) => {
                                                this.change('translate', value, index)
                                            }}
                                        />
                                        {/* <TextArea value={item.translate} onChange={(e) => { this.change('translate', e.target.value, index) }} autoSize={{ minRows: 20, maxRows: 20 }} /> */}
                                    </Col>
                                </Row>
                            </div>
                        )
                    })
                }

            </div>
        )
    }
}

class AboutArticle extends React.Component {
    state = {
        books: [{ keyTime: (new Date()).getTime() }]
    }
    add() {
        let { change, value = [] } = this.props;
        value.push({ keyTime: (new Date()).getTime() });

        change(value)
    }
    del(index) {
        let { change, value = [] } = this.props;
        value.splice(index, 1);

        change(value)
    }
    change(key, changeValue, index) {
        let { change, value = [] } = this.props;

        value[index][key] = changeValue;

        change(value)

    }
    render() {
        let { value = [] } = this.props;
        return (
            <div className="add-article-book">
                <div className="add" onClick={this.add.bind(this)}>+新增</div>
                {
                    value.map((item, index) => {
                        return (
                            <div key={item.keyTime || item.id} className="book-item">
                                <Row>
                                    <Col span={2}>
                                        <div className="label">文章标题:</div>
                                    </Col>
                                    <Col span={9}><Input onChange={(e) => { this.change('title', e.target.value, index) }} value={item.title} /></Col>
                                    <Col span={2}>
                                        <div className="label">地址:</div>
                                    </Col>
                                    <Col span={9}><Input placeholder="内容如：id=文章id" onChange={(e) => { this.change('url', e.target.value, index) }} value={item.url} /></Col>
                                    <Col span={2}>
                                        <div className="del" onClick={() => {
                                            this.del(index)
                                        }}>删除</div>
                                    </Col>
                                </Row>
                            </div>
                        )
                    })
                }

            </div>
        )
    }
}

class AddTags extends React.Component {
    state = {
        tagList: [],
        searchText: '',
    }
    tagsChange(checkedValue) {

        this.setState({
            tagList: checkedValue
        });

    }
    itemtagsChange(itemKey, checkedValue) {
        let { value = [], change, element } = this.props;
        let flag = true;

        value.forEach(item => {
            if (item.id == itemKey) {
                flag = false;
                item.items = checkedValue
            }
        })
        if (flag) {
            value.push({
                id: itemKey,
                items: checkedValue
            })
        }
        change(value)
    }
    searchChange = (e) => {
        this.setState({
            searchText: e.target.value
        })
    }
    render() {
        let { searchText, tagList } = this.state;
        let { value = [], element } = this.props;
        let showSelectList = []

        value.map(item => {
            [].push.apply(showSelectList, item.items)
            return item.id
        })

        let options = element.option.map(item => {
            return {
                label: item.name,
                value: item.id,
                items: item.items
            }
        })

        return (
            <div className="add-article-book">
                <div className="tag-search-box">
                    <Input value={searchText} onChange={this.searchChange} />
                </div>
                <Row className="select-box">
                    <Col span={24}>
                        <Col
                            span={24}
                        >
                            选中的标签：
                        </Col>
                        {
                            showSelectList.map(item => {
                                return (
                                    <Col
                                        key={item}
                                        span={4}
                                    >
                                        <Checkbox disabled defaultChecked >{item}</Checkbox>
                                    </Col>
                                )

                            })
                        }
                    </Col>
                </Row>
                <Row>
                    <Checkbox.Group options={options} value={tagList} onChange={(checkedValue) => { this.tagsChange(checkedValue) }} />
                </Row>
                {

                    options.map(item => {
                        let defaultValue = [];
                        let show = false;
                        value.forEach(ele => {
                            if (ele.id === item.value) {
                                defaultValue = ele.items;
                                console.log(defaultValue)
                            }
                        });
                        // 分查询和不查询2种情况
                        if (searchText) {
                            item.items.forEach(ele => {
                                if (ele.includes(searchText)) {
                                    show = true
                                }
                            })
                        } else {
                            if (tagList.includes(item.value)) {
                                show = true
                            }
                        }

                        return show ? (
                            <div key={item.value} style={{ background: '#f2f2f2', marginTop: '16px' }}>
                                <Row>{item.label}</Row>
                                <Row>
                                    <Checkbox.Group style={{ width: '100%' }} defaultValue={defaultValue} onChange={(checkedValue) => { this.itemtagsChange(item.value, checkedValue) }} >
                                        <Row>
                                            {
                                                item.items.map(item => {
                                                    let showItem = false;
                                                    // 分查询和不查询2种情况
                                                    if (searchText) {
                                                        showItem = item.includes(searchText)
                                                    } else {
                                                        showItem = true
                                                    }
                                                    return (
                                                        <Col
                                                            style={{ display: `${showItem ? '' : 'none'}` }}
                                                            key={item}
                                                            span={4}
                                                        >
                                                            <Checkbox value={item}>{item}</Checkbox>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Checkbox.Group>
                                </Row>
                            </div>
                        ) : null
                    })
                }
            </div>
        )
    }
}


class UploadList extends React.Component {
    state = {
        showAddModal: false,
        upList: []
    }
    showModal() {
        let { value } = this.props;

        this.setState({
            showAddModal: true,
            upList: [
                ...value
            ]
        })
    }
    closeModal() {
        this.setState({
            showAddModal: false,
        })
    }
    onOk() {
        let { upList } = this.state;
        let { change } = this.props;

        change(upList);

        this.setState({
            showAddModal: false,
        })

    }
    fileOnChange({ file }) {
        if (file.status === "done") {
            if (file.response.code === 1) {
                this.setState({
                    upList: [
                        ...this.state.upList,
                        {
                            cover_img: file.response.data.url
                        }
                    ]
                })
            } else {
                Modal.error({ content: file.response.msg });
                // fileList.pop();
            }
        } else if (file.status === "error") {
            Modal.error({ content: "上传失败" });
            // fileList.pop();
        }
    }
    remove(index){
        let { upList } = this.state;

        upList.splice(index,1)
        this.setState({
          upList  
        })
    }
    changeUpList(key, value, index) {
        let { upList } = this.state;

        upList[index][key] = value;

        this.setState({
            upList: [
                ...upList
            ]
        })
    }
    render() {
        let { showAddModal, upList } = this.state;
        let { value } = this.props;
        return <React.Fragment>
            <div className="show-upload-list">
                {
                    value.map((item, index) => {
                        return <div className="upload-item">
                            <div className="img image-box" style={{ backgroundImage: `url(${item.cover_img})` }}></div>
                            <div className="hs">画师:{item.pic_author}</div>
                            <div className="img-name">图名:{item.cover_img_name}</div>
                        </div>
                    })
                }

                <div className="add" onClick={() => { this.showModal() }}>+</div>
            </div>
            <Modal
                width="600px"
                onOk={() => { this.onOk() }}
                onCancel={() => { this.closeModal() }}
                visible={showAddModal}
            >
                <div className="article-upload-list">
                    {
                        upList.map((item, index) => {
                            return <div className="upload-item">
                                <div className="remove" onClick={(e)=>{this.remove(index)}}>X</div>
                                <div className="img image-box" style={{ background: `url(${item.cover_img}) no-repeat center center/cover` }}></div>
                                <Input
                                    onChange={(e) => { this.changeUpList('pic_author', e.target.value, index) }}
                                    value={item.pic_author}
                                    className="hs" placeholder="请输入画师名字" />
                                <Input
                                    onChange={(e) => { this.changeUpList('cover_img_name', e.target.value, index) }}
                                    value={item.cover_img_name}
                                    className="img-name" placeholder="请输入图片名称" />
                            </div>
                        })
                    }

                    <Upload
                        headers={{
                            "Authorization": getToken()
                        }}
                        showUploadList={false}
                        name={'file'}
                        action={commonUrl.imgUp}
                        onChange={this.fileOnChange.bind(this)}
                    >
                        <div className="add">+</div>
                    </Upload>
                </div>
            </Modal>
        </React.Fragment >
    }
}

class Article extends React.Component {
    state = {
        id: 0,
        category: 4,
        typeList: [
            {
                code: 1,
                name: '广告'
            },
            {
                code: 2,
                name: '杂谈'
            },
            {
                code: 3,
                name: '夜谭'
            },
            {
                code: 4,
                name: '百妖'
            }
        ],
        configData: [],
        tagsData: [],
        wordList: [],
        routeIntercept: true
    }
    async componentWillMount() {
        let { category } = this.state;
        let { match, location } = this.props;
        let id = parseInt(match.params.id);
        let search = qs.parse(location.search.replace('?', ''))
        category = parseInt(search.category || category)

        let tagsData = await $http.postData(artcleUrl.getTagsList);
        let wordList = await $http.postData(artcleUrl.getWordList2, { page_num: 1, page_size: 10000 });
        this.setState({
            id: id || 0,
            category,
            tagsData: tagsData.data,
            wordList: wordList.data.list
        }, () => {
            if (id) {
                $http.postData(artcleUrl.detail, { id }).then(data => {
                    if (category === 1) {
                        this.setState({
                            configData: this.initEditConfig1(data.data)
                        })
                    } else if (category === 2 || category === 3) {
                        this.setState({
                            configData: this.initEditConfig23(data.data)
                        })
                    } else {
                        this.setState({
                            configData: this.initEditConfig4(data.data)
                        })
                    }

                    if(data.data.push_time){
                        this.setState({
                            showTimeBtn: true
                        })
                    }

                })

            } else {
                this.setState({
                    configData: this.initEditConfig4({})
                })
            }
        })


    }
    save(status) {
        let { history } = this.props;
        let { category, id } = this.state;
        let params = this.MyForm.getfieldsValue();
        if (params) {
            params.category = category;
            params.id = id;
            params.status = status;
            params.is_allow_comment = params.is_allow_comment ? 1 : 2;
            $http.postData(artcleUrl.save, params).then(data => {
                message.success('成功');
                this.setState({
                    routeIntercept: false
                }, () => {
                    // history.go(-1)
                    window.close()
                })
            })
        }

    }
    remove() {
        let { history } = this.props;
        let { id } = this.state;
        Modal.confirm({
            icon: <Icon type="warning" />,
            content: '确定删除文章吗',
            onOk: () => {
                $http.postData(artcleUrl.remove, { id }).then(data => {
                    this.setState({
                        routeIntercept: false
                    }, () => {
                        history.go(-1)
                    })
                })
            }
        })

    }
    initEditConfig4 = (lineData) => {
        let { tagsData } = this.state;
        return [
            {
                col: 24,
                id: 'is_allow_comment',
                formItemLayout,
                label: '允许讨论',
                type: 'checkBoxItem',
                initialValue: lineData.is_allow_comment === 2 ? false : true
            },
            {
                col: 24,
                id: 'is_push',
                formItemLayout,
                label: 'APP是否推送',
                type: 'radio',
                option: [
                    {
                        code: 2,
                        name: '否'
                    },
                    {
                        code: 1,
                        name: '是'
                    }
                ],
                initialValue: lineData.is_push || 1
            },
            {
                col: 24,
                id: 'push_time',
                formItemLayout,
                label: 'APP定时发布',
                type: 'date',
                format: 'yyyy-MM-dd HH:mm:ss',
                change: (date,dataString) => {
                    if(date){
                        this.setState({
                            showTimeBtn: true
                        })
                    }else{
                        this.setState({
                            showTimeBtn: false
                        })
                    }
                },
                initialValue: lineData.push_time
            },
            {
                col: 24,
                id: 'title',
                formItemLayout,
                label: '标题',
                rules: [{ required: true, message: '必填' }, { max: 50, message: '不能超过50长度' }],
                type: 'text',
                initialValue: lineData.title
            },
            {
                col: 24,
                id: 'introduction',
                formItemLayout,
                label: '摘要',
                rules: [{ required: true, message: '必填' },{ max: 150, message: '不能超过150长度' }],
                type: 'text',
                initialValue: lineData.introduction
            },
            {
                col: 24,
                id: 'author',
                formItemLayout,
                label: '作者',
                rules: [{ required: true, message: '必填' }, { max: 20, message: '不能超过20长度' }],
                type: 'text',
                initialValue: lineData.author
            },
            {
                col: 24,
                id: 'pic_author',
                formItemLayout,
                label: '画师',
                type: 'text',
                initialValue: lineData.pic_author
            },
            {
                col: 24,
                id: 'cover_img_name',
                formItemLayout,
                label: '封面图名称',
                type: 'text',
                initialValue: lineData.cover_img_name
            },
            {
                col: 24,
                id: 'cover_img',
                formItemLayout,
                label: '封面图',
                type: 'img',
                initialValue: lineData.cover_img && [{
                    uid: '-1',
                    status: 'done',
                    url: lineData.cover_img,
                }],
            },

            {
                col: 24,
                id: 'content_imgs',
                formItemLayout,
                type: 'custom',
                label: '内容图',
                initialValue: lineData.content_imgs || [],
                component: UploadList
            },
            {
                col: 24,
                id: 'content',
                formItemLayout,
                label: '正文',
                type: 'richText',
                initialValue: lineData.content
            },
            {
                col: 24,
                id: 'books',
                formItemLayout,
                label: '典籍',
                type: 'custom',
                initialValue: lineData.books,
                component: Book
            },
            {
                col: 24,
                id: 'tags',
                formItemLayout,
                label: '标签',
                type: 'custom',
                option: tagsData,
                initialValue: lineData.tags,
                component: AddTags
            },
            {
                col: 24,
                id: 'relation',
                formItemLayout,
                label: '相关文章',
                type: 'custom',
                initialValue: lineData.relation,
                component: AboutArticle
            }
        ]
    }
    initEditConfig23 = (lineData) => {
        let { tagsData, wordList } = this.state;
        return [
            {
                col: 24,
                id: 'is_allow_comment',
                formItemLayout,
                label: '允许讨论',
                type: 'checkBoxItem',
                initialValue: lineData.is_allow_comment === 2 ? false : true
            },
            {
                col: 24,
                id: 'title',
                formItemLayout,
                rules: [{ required: true, message: '必填' }, { max: 50, message: '不能超过50长度' }],
                label: '标题',
                type: 'text',
                initialValue: lineData.title
            },
            {
                col: 24,
                id: 'introduction',
                formItemLayout,
                label: '摘要',
                rules: [{ required: true, message: '必填' }],
                type: 'text',
                initialValue: lineData.introduction
            },
            {
                col: 24,
                id: 'author',
                formItemLayout,
                label: '作者',
                type: 'text',
                initialValue: lineData.author
            },
            {
                col: 24,
                id: 'word_id',
                formItemLayout,
                label: '收录于妖怪词条',
                type: 'select',
                option: wordList.map(item => {
                    return {
                        code: item.id,
                        name: item.name
                    }
                }),
                initialValue: lineData.word_id
            },
            {
                col: 24,
                id: 'pic_author',
                formItemLayout,
                label: '画师',
                type: 'text',
                initialValue: lineData.pic_author
            },
            {
                col: 24,
                id: 'cover_img_name',
                formItemLayout,
                label: '封面图名称',
                type: 'text',
                initialValue: lineData.cover_img_name
            },
            {
                col: 24,
                id: 'cover_img',
                formItemLayout,
                label: '封面图',
                type: 'img',
                initialValue: lineData.cover_img && [{
                    uid: '-1',
                    status: 'done',
                    url: lineData.cover_img,
                }]
            },
            // {
            //     col: 24,
            //     id: 'content_img',
            //     formItemLayout,
            //     label: '内容图',
            //     type: 'img',
            //     initialValue: lineData.content_img && [{
            //         uid: '-1',
            //         status: 'done',
            //         url: lineData.content_img,
            //     }]
            // },
            {
                col: 24,
                id: 'content_imgs',
                formItemLayout,
                type: 'custom',
                label: '内容图',
                initialValue: lineData.content_imgs || [],
                component: UploadList
            },
            {
                col: 24,
                id: 'content',
                formItemLayout,
                label: '正文',
                type: 'richText',
                initialValue: lineData.content
            },
            {
                col: 24,
                id: 'books',
                formItemLayout,
                label: '典籍',
                type: 'custom',
                initialValue: lineData.books,
                component: Book
            },
            {
                col: 24,
                id: 'tags',
                formItemLayout,
                label: '标签',
                type: 'custom',
                option: tagsData,
                initialValue: lineData.tags,
                component: AddTags
            },
            {
                col: 24,
                id: 'relation',
                formItemLayout,
                label: '相关文章',
                type: 'custom',
                initialValue: lineData.relation,
                component: AboutArticle
            }
        ]
    }
    initEditConfig1 = (lineData) => {
        return [
            {
                col: 24,
                id: 'title',
                formItemLayout,
                label: '标题',
                rules: [{ required: true, message: '必填' }, { max: 50, message: '不能超过50长度' }],
                type: 'text',
                initialValue: lineData.title
            },
            {
                col: 24,
                id: 'pic_author',
                formItemLayout,
                label: '画师',
                type: 'text',
                initialValue: lineData.pic_author
            },
            {
                col: 24,
                id: 'cover_img_name',
                formItemLayout,
                label: '封面图名称',
                type: 'text',
                initialValue: lineData.cover_img_name
            },
            {
                col: 24,
                id: 'cover_img',
                formItemLayout,
                label: '封面图',
                rules: [{ required: true, message: '必填' }],
                type: 'img',
                initialValue: lineData.cover_img && [{
                    uid: '-1',
                    status: 'done',
                    url: lineData.cover_img,
                }]
            },

            {
                col: 24,
                id: 'content_imgs',
                formItemLayout,
                type: 'custom',
                label: '内容图',
                initialValue: lineData.content_imgs || [],
                component: UploadList
            },

            {
                col: 24,
                id: 'content',
                formItemLayout,
                label: '正文',
                type: 'richText',
                initialValue: lineData.content
            }
        ]
    }
    typeChange(value) {

        this.setState({ category: value })
        if (value === 1) {
            this.setState({
                configData: this.initEditConfig1({})
            })
        } else if (value === 2 || value === 3) {
            this.setState({
                configData: this.initEditConfig23({})
            })
        } else {
            this.setState({
                configData: this.initEditConfig4({})
            })
        }
    }

    render() {
        let { configData, typeList, category, id, routeIntercept,showTimeBtn } = this.state;
        let { location } = this.props;
        let search = qs.parse(location.search.replace('?', ''))
        let authList = localStorage.getItem('zhiyao-auth-list') || [];
        return (
            <div className="page-articel">
                <Prompt when={routeIntercept} message="确定离开当前页面吗?" />

                <Form.Item
                    {...formItemLayout}
                    label="分类"
                    name="分类"
                >
                    <Select disabled={search.category ? true : false} style={{ width: '100%' }} value={category} onChange={this.typeChange.bind(this)}>
                        {
                            typeList.map(item => {
                                return (
                                    <Option key={item.code} value={item.code}>{item.name}</Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
                <MyForm key={category} wrappedComponentRef={(form) => this.MyForm = form} data={configData} />
                <Row>
                    <Col span={7}></Col>
                    <Col span={15}>
                        <div className="active-box">
                            {
                                id !== 0 ? <Button type="primary" danger onClick={this.remove.bind(this)}>删除</Button> : null
                            }
                            {
                                category === 1 ? (authList.includes('article.adv') ?
                                    <React.Fragment>
                                        <Button onClick={this.save.bind(this, 2)}>保存为草稿</Button>
                                        <Button type="primary" onClick={this.save.bind(this, 1)}>保存并发布</Button>
                                    </React.Fragment> : null)
                                    :
                                    <React.Fragment>
                                        <Button onClick={this.save.bind(this, 2)}>保存为草稿</Button>
                                        {
                                            !(category === 4 && showTimeBtn)?<Button type="primary" onClick={this.save.bind(this, 1)}>保存并发布</Button>:null
                                        }
                                        
                                        {
                                            category === 4 && showTimeBtn ?
                                                <Button type="primary" onClick={this.save.bind(this, 3)}>保存并定时发布</Button> : null
                                        }
                                    </React.Fragment>
                            }
                        </div>

                    </Col>
                </Row>

            </div>
        );
    }
}

export default withRouter(Article);
