import React from "react";
import {
    Link,
    Route,
    Switch,
    Redirect
} from "react-router-dom";
import { Layout, Menu, Breadcrumb, Icon, Modal, message } from 'antd';
import MyModal from "../../components/MyModal";
import $http from "../../common/fetch";
import { loginUrl } from '../../common/url';
import './index.css'

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

class Main extends React.Component {
    state = {
        modalShow: false,
        userInfo: {},
        modalConfig: {},
        openIds: [],
        currentPath: [],
        authMenuList: []
    }
    componentWillMount() {
        let authList = localStorage.getItem('zhiyao-auth-list') || [];
        let { menuList, location } = this.props;
        let { openIds, currentPath } = this.state;
        let pathname = location.pathname;
        let authMenuList = [];

        this.getUserInfo()

        menuList.forEach(item => {
            let children = [];
            item.children.forEach(ele => {
                if (ele.path === pathname) {
                    openIds.push(item.id)
                    currentPath.push(ele.id)
                }
                if (authList.includes(ele.auth)) {
                    children.push(ele)
                }
            })
            if (children.length) {
                item.children = children;
                authMenuList.push(item)
            }
        });
        this.setState({
            openIds,
            currentPath,
            authMenuList
        })
    }
    getUserInfo() {
        $http.postData(loginUrl.userInfo).then(data => {
            this.setState({
                userInfo: data.data
            })
        })
    }
    editPassWord() {
        this.setState({
            modalShow: true,
            modalConfig: this.initModalConfig()
        })
    }
    handleOk(data) {
        if (data.new !== data.surenew) {
            Modal.error({
                content: '2次密码输入不一致'
            })
        } else {
            $http.postData(loginUrl.upDate, data).then(data => {
                this.setState({
                    modalShow: false
                })
                message.success('成功');
            })
        }
    }
    cancel = () => {
        this.setState({
            modalShow: false
        })
    }
    goOut() {
        let { history } = this.props;
        localStorage.removeItem('zhiyao-token');
        history.replace('/')

    }
    initModalConfig = (lineData) => {
        return {
            title: "修改密码",
            width: 800,
            data: [
                {
                    col: 24,
                    id: 'origin',
                    label: '原始密码',
                    type: 'password',
                },
                {
                    col: 24,
                    id: 'new',
                    label: '新密码',
                    type: 'password',
                },
                {
                    col: 24,
                    id: 'surenew',
                    label: '确认新密码',
                    type: 'password',
                }
            ]
        }


    }
    renderRoute() {
        let { authMenuList } = this.state;

        return (<Switch>

            {
                authMenuList.map(item => {
                    return item.children.map(ele => {
                        return <Route
                            key={ele.path}
                            exact={ele.exact || false}
                            path={ele.path}

                        >
                            {
                                React.createElement(ele.component, {
                                    menuList: item.children
                                })
                            }

                        </Route>
                    })
                })
            }
            <Route path="/main" render={() => <Redirect to={authMenuList[0].children[0].path} />} />
        </Switch>)

    }
    render() {
        let { modalShow, modalConfig, openIds, currentPath, userInfo, authMenuList } = this.state;
        return (
            <div className="main_body">
                <Layout style={{ minHeight: '100vh' }}>
                    <Sider>
                        <div className="company-icon">
                            <div className="logo"></div>
                            <div className="logo-name">知妖</div>
                        </div>
                        <Menu theme="dark" defaultOpenKeys={openIds} defaultSelectedKeys={currentPath} mode="inline">
                            {
                                authMenuList.map(item => {
                                    return (
                                        <SubMenu
                                            key={item.id}
                                            title={
                                                <span>
                                                    {
                                                        item.icon
                                                    }
                                                    <span>{item.title}</span>
                                                </span>
                                            }
                                        >
                                            {
                                                item.children.map(ele => {
                                                    return (ele.hidden ? null : <Menu.Item key={ele.id}><Link to={ele.path}>{ele.title}</Link></Menu.Item>)
                                                })

                                            }
                                        </SubMenu>
                                    )
                                })
                            }
                        </Menu>
                    </Sider>
                    <Layout style={{ background: '#fff', display: 'flex', flexDirection: 'column', height: '100vh' }}>
                        <Header style={{ height: '64px', padding: 0, display: 'flex', justifyContent: 'flex-end', borderBottom: '1px solid #eee', flexShrink: 0, background: '#fff' }} >
                            <div className="user-info">
                                <div className="name">{userInfo.name}</div>
                                <div onClick={this.editPassWord.bind(this)} className="edit-password">修改密码</div>
                                <div onClick={this.goOut.bind(this)} className="go-out">退出</div>
                            </div>
                        </Header>
                        <Content style={{ background: '#fff', margin: '0 16px', flex: 1, overflowX: 'hidden', overflowY: 'auto', marginRight: 0 }}>
                            {this.renderRoute()}
                        </Content>
                    </Layout>
                </Layout>
                {
                    modalShow ? <MyModal
                        ref={node => (this.MyModal = node)}
                        modalConfig={modalConfig}
                        visible={modalShow}
                        handleOk={this.handleOk}
                        cancel={this.cancel}
                    /> : null
                }
            </div>
        );
    }
}

export default Main;