import { renderTextStyle } from './render-text-style'
import { textStyleToHtml } from './text-style-to-html'
import LetterSpace from './menu/index'

const LetterSpacing = {
    renderTextStyle,
    textStyleToHtml,
    menus: [LetterSpace],
}

export default LetterSpacing

