import { Icon } from 'antd'
import Login from "../view/Login";
import Main from "../view/Main";
import UserList from "../view/UserList"
import UserComments from "../view/UserComments"
import SystemInfo from "../view/SystemInfo"
import AllArticle from '../view/AllArticle'
import Article from '../view/Article'
import Role from '../view/Role'
import User from '../view/User'

const router = [
    {
        id: "login",
        path: "/login",
        exact: true,
        component: Login
    },
    {
        id: "main",
        path: "/main",
        component: Main,
        children: [
            {
                id: "user",
                title: '用户管理',
                icon: <Icon type="usergroup-add" />,
                children: [
                    {
                        id: "user-list",
                        auth: 'user.view',
                        path: "/main/user-list",
                        title: '用户列表',
                        component: UserList
                    },
                    {
                        id: "user-comments",
                        auth: 'comment.view',
                        path: "/main/user-comments",
                        title: '用户评论',
                        component: UserComments
                    },
                    {
                        id: "system-info",
                        path: "/main/system-info",
                        auth: 'message.view',
                        title: '系统消息',
                        component: SystemInfo
                    }
                ]
            },
            {
                id: "content",
                title: '内容管理',
                icon: <Icon type="edit" />,
                children: [
                    {
                        id: "all-article",
                        auth: "article.view",
                        path: "/main/all-article",
                        title: '所有文章',
                        component: AllArticle
                    },
                    {
                        id: "article-add",
                        auth: "all",
                        path: "/main/article",
                        exact: true,
                        hidden: true,
                        title: '文章编辑',
                        component: Article
                    },
                    {
                        id: "article-edit",
                        auth: "all",
                        path: "/main/article/:id",
                        exact: true,
                        hidden: true,
                        title: '文章编辑',
                        component: Article
                    }
                ]
            },
            {
                id: "auth",
                title: '权限管理',
                icon: <Icon type="user" />,
                children: [
                    {
                        id: "role-manage",
                        auth: "role.view",
                        path: "/main/role-manage",
                        title: '角色管理',
                        component: Role
                    },
                    {
                        id: "user",
                        auth: "member.view",
                        path: "/main/user",
                        title: '用户管理',
                        component: User
                    }
                ]
            }
        ]
    }
];

export default router;
