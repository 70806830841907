import LetterSpaceMenu from './LetterSpaceMenu'
import { genLetterSpaceConfig } from './config'

const LetterSpaceMenuConf = {
    key: 'letterSpace',
    factory() {
        return new LetterSpaceMenu()
    },

    // 默认的菜单菜单配置，将存储在 editorConfig.MENU_CONF[key] 中
    // 创建编辑器时，可通过 editorConfig.MENU_CONF[key] = {...} 来修改
    config: {
        LetterSpaceList: genLetterSpaceConfig(),
    },
}

export default LetterSpaceMenuConf