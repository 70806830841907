import { Text, Element } from 'slate'
import { addVnodeStyle } from '../utils/vdom'

/**
 * 添加文本样式
 * @param node slate node
 * @param vnode vnode
 * @returns vnode
 */
export function renderTextStyle(node, vnode) {
    if (!Element.isElement(node)) return vnode

    const { LetterSpace } = node
    let styleVnode = vnode

    console.log(styleVnode)

    if (LetterSpace) {
        addVnodeStyle(styleVnode, { letterSpacing: LetterSpace })
    }

    return styleVnode
}