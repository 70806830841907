/**
 * 给 vnode 添加样式
 * @param vnode vnode
 * @param newStyle { key: val }
 */
export function addVnodeStyle(vnode, newStyle) {
    if (vnode.data == null) vnode.data = {}
    const data = vnode.data
    if (data.style == null) data.style = {}

    Object.assign(data.style, newStyle)
}