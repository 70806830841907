let host =  "";

// eslint-disable-next-line no-restricted-globals
if (location.href.includes('test.web') || location.href.includes('http://localhost')){
    host = 'https://test.api.cbaigui.com/'
}else{
    host = "https://api.cbaigui.com/";
}

export const commonUrl = {
    menu: `${host}/base/menu?type=web`,
    imgUp: `${host}upload/img`
};
export const userUrl = {
    list: `${host}admin/user/search`,
    sendMess: `${host}admin/message/send`,
    toggle: `${host}admin/user/toggle`,
    commentSearch: `${host}admin/comment/search`,
    forbidden: `${host}admin/comment/forbidden`,
    messageSearch: `${host}admin/message/search`,
    addSysMess: `${host}admin/message/submit`
}

export const artcleUrl = {
    list: `${host}admin/article/search`,
    authorList: `${host}admin/author/list`,
    toggle: `${host}admin/article/toggle`,
    detail: `${host}admin/article/detail`,
    save: `${host}admin/article/save`,
    getTagsList: `${host}admin/tag/list`,
    getWordList: `${host}admin/words/list`,
    getWordList2: `${host}admin/words/list/v2`,
    remove: `${host}admin/article/delete`,
}

export const roleUrl = {
    list: `${host}admin/role/search`,
    authList: `${host}admin/privilege/list`,
    save: `${host}admin/role/save`,
    toggle: `${host}admin/role/toggle`
}
export const memberUrl = {
    list: `${host}admin/member/role/search`,
    getRoleList: `${host}admin/role/list`,
    toggle: `${host}admin/member/forbidden`,
    save: `${host}admin/member/save`,
}
export const loginUrl = {
    login: `${host}admin/auth/login`,
    upDate: `${host}admin/member/password/update`,
    userInfo: `${host}admin/member/info`,
    privileges: `${host}admin/privileges`
};