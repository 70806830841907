import React from "react";
import { Table, Modal, Button, Dropdown, Icon, Menu, Upload, Input, message } from "antd";
import TablePage from "../../components/TablePage";
import $http from "../../common/fetch";
import { userUrl } from '../../common/url'

let searchFormConfig = {
    form: [
        {
            title: '用户昵称',
            id: 'name',
            type: 'text',
        }
    ]
}

class UserList extends React.Component {
    state = {
        handleOk: (...res) => {
            this.saveSendMessage(...res)
        }
    }
    componentWillMount() {

    }
    changeStatus = (lineData) => {
        new Promise((resovle) => {
            if (lineData.status === 1) {
                Modal.confirm({
                    icon: <Icon type="warning" />,
                    title: `你正在禁用 ${lineData.name} 用户`,
                    content: '禁用后该用户将无法评论，你还要继续吗？',
                    onOk: () => {
                        resovle(2)
                    }
                })
            } else {
                resovle(1)
            }
        }).then((status) => {
            $http.postData(userUrl.toggle, { id: lineData.id, status }).then(data => {
                message.success('成功');
                this.TablePage.getServerData();
            })
        })

    }
    sendMessage = (lineData) => {
        this.TablePage.modalEdit(
            this.initSendMessageConfig(lineData)
        );
    }
    saveSendMessage = (params, model) => {
        model.close();
        $http.postData(userUrl.sendMess, params).then(data => {
            message.success('成功');
            this.TablePage.getServerData();
        })
    }
    initSendMessageConfig = (lineData) => {
        return {
            title: "发送通知",
            width: 800,
            data: [
                {
                    show: false,
                    col: 24,
                    id: 'user_id',
                    label: 'id',
                    type: 'text',
                    initialValue: lineData.id
                },
                {
                    disabled: true,
                    col: 24,
                    id: 'userName',
                    label: '发送给',
                    type: 'text',
                    initialValue: lineData.name
                },
                {
                    col: 24,
                    id: 'title',
                    label: '标题',
                    type: 'text',
                },
                {
                    col: 24,
                    id: 'content',
                    label: '正文',
                    type: 'textarea',
                },
                // {
                //     col: 24,
                //     id: 'richText',
                //     label: '富文本',
                //     type: 'richText',
                //     initialValue: '<p>单调递增/17721065665</p>',
                // },
                // {
                //     col: 24,
                //     id: 'custom',
                //     label: '自定义组价',
                //     type: 'custom',
                //     initialValue: '单调递增/17721065665',
                //     component: function ({ change, value }) {
                //         return <Input defaultValue={value} onChange={(e) => { change(e.target.value) }} placeholder="Basic usage" />
                //     }
                // },
            ]
        }
    }
    render() {
        let authList = localStorage.getItem('zhiyao-auth-list') || [];
        let { handleOk } = this.state;
        let headColumns = [
            {
                title: '用户编号',
                dataIndex: 'number',
                key: 'number',
            },
            {
                title: '用户昵称',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '用户状态',
                dataIndex: 'status',
                key: 'status',
                render: (text, record) => {
                    return record.status === 1 ? '启用' : '禁用'
                }
            },
            {
                title: '操作',
                dataIndex: 'operation',
                render: (text, record) => {
                    return (
                        <React.Fragment>
                            {   authList.includes('user.toggle') &&
                                (record.status === 1 ?
                                    <Button onClick={() => {
                                        this.changeStatus(record)
                                    }}>禁用</Button>
                                    :
                                    <Button onClick={() => {
                                        this.changeStatus(record)
                                    }}>启用</Button>)
                            }
                            {authList.includes('user.send') && <Button style={{ marginLeft: '10px' }} onClick={() => {
                                this.sendMessage(record)
                            }}>发送通知</Button>}
                            
                        </React.Fragment>
                    )
                }
            }
        ]
        return (
            <div className="page-user-list">
                <TablePage
                    ref={node => (this.TablePage = node)}
                    pageLine={['用户管理', '用户列表']}
                    headColumns={headColumns}
                    handleOk={handleOk.bind(this)}
                    loadStore={userUrl.list}
                    searchFormConfig={searchFormConfig}
                ></TablePage>
            </div>
        );
    }
}

export default UserList;
