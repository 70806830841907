/**
 * form表单
 * 1.获取表单的值
 * 2.支持配置元素布局 col
 * 3.支持配置单个元素布局 formItemLayout
 * 4.支持 多选框,单选框,下拉框,下拉多选框,下拉树形框,文件上传(不支持多文件),输入框,文本框,密码框,日历,树形,潘通色卡,自定义的多选框加下拉框,时间控件(年月,年月日,年月日时分秒,通过format控制)
 * 5.可以通过外部props传入产生字段的联动
*/
import React from "react";
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import '@wangeditor/editor/dist/css/style.css'
import { IDomEditor, IEditorConfig, IToolbarConfig, SlateDescendant } from '@wangeditor/editor'
import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import './wangEditplugin'

import {
  Modal,
  Form,
  Input,
  Select,
  Cascader,
  DatePicker,
  Checkbox,
  Row,
  Col,
  Upload,
  Button,
  Icon,
  Radio,
  TreeSelect,
  Tree
} from "antd";
import { SketchPicker } from "react-color";
import $http from "../../common/fetch";
import { commonUrl } from "../../common/url";
import moment from "moment";
let realroot = '';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const TreeNode = TreeSelect.TreeNode;
const { MonthPicker } = DatePicker;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 15 }
  }
};


let getToken = () => {
  return localStorage.getItem("zhiyao-token") || undefined
}


// 自定义组件
class MyCustom extends React.Component {
  state = {}
  static getDerivedStateFromProps(nextProps) {
    // Should be a controlled component.
    if ("value" in nextProps) {
      return {
        ...(nextProps.value || {})
      };
    }
    return null;
  }

  triggerChange = changedValue => {
    // Should provide an event to pass value to Form.
    const onChange = this.props.onChange;
    if (onChange) {
      onChange(changedValue);
    }
  };
  render() {
    let { component, value, element } = this.props;
    return (
      <React.Fragment>
        {
          React.createElement(component, {
            element,
            change: this.triggerChange,
            value: value
          })
        }
      </React.Fragment>
    );
  }
}
export class RichText extends React.Component {
  state = {
    editorState: null
  }
  handleChange = (editorState) => {
    let { change } = this.props;
    this.setState({
      editorState
    }, () => {
      change(editorState.toHTML())
    })
  }
  uploadFn = (param) => {
    let formData = new FormData();
    formData.append('file', param.file)

    $http.postData(commonUrl.imgUp, {}, {
      headers: {
        Authorization: getToken()
      },
      body: formData
    }).then(data => {
      param.success({
        url: data.data.url,
        meta: {
          loop: false, // 指定音视频是否循环播放
          autoPlay: false, // 指定音视频是否自动播放
          controls: true, // 指定音视频是否显示控制栏
        }
      })
    })
  }
  render() {
    let { value } = this.props;
    let { editorState } = this.state;
    return (
      <React.Fragment>
        <BraftEditor
          className="my-editor"
          value={editorState || BraftEditor.createEditorState(value)}
          onChange={this.handleChange}
          media={{ uploadFn: this.uploadFn }}
          style={{ border: '1px solid #e8e8e8' }}
          placeholder="请输入正文内容" />

      </React.Fragment>
    );
  }
}



// class RichText extends React.Component {
//   state = {
//     editor: null,
//   }
//   handleChange = (editorState) => {
//     let { change } = this.props;
//   }
//   componentWillMount() {


//   }

//   render() {
//     let { editor } = this.state;
//     const toolbarConfig = {
//       insertKeys: {
//         index: 1, // 插入的位置，基于当前的 toolbarKeys
//         keys: ['letterSpace']
//       },
//     }
//     const editorConfig = { }
//     editorConfig.placeholder = '请输入内容...'
//     editorConfig.onCreated = (editor) => {
//       // 记录 editor 实例，重要 ！
//       // 有了 editor 实例，就可以执行 editor API
//       this.setState({
//         editor
//       })

//     }
//     editorConfig.onChange = (editor) => {
//       // editor 选区或者内容变化时，获取当前最新的的 content
//       console.log(editor.getHtml())
//     }

//     return (
//       <React.Fragment>
//         <div style={{ border: '1px solid #ccc' }}>
//           <Toolbar
//             defaultConfig={toolbarConfig}
//             editor={editor}
//             mode="default"
//             style={{ borderBottom: '1px solid #ccc' }}
//           />

//           <Editor
//             defaultConfig={editorConfig}
//             mode="default"
//             style={{ height: '500px', border: '1px solid #ccc' }}
//           />
//         </div>
//       </React.Fragment>
//     );
//   }
// }

class MyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getfieldsValue = () => {
    let { data } = this.props;
    let resultData;
    let { validateFields, validateFieldsAndScroll } = this.props.form;
    let flag = true;
    validateFields((err, fieldsValue) => {
      if (err) {
        validateFieldsAndScroll();
        flag = false;
        return;
      } else {
        resultData = fieldsValue;
        data.forEach(item => {
          if (
            (item.type === "img" || item.type === "file") &&
            !!resultData[item.id] &&
            !!resultData[item.id].length
          ) {
            resultData[item.id] = (resultData[item.id][0].response
              && resultData[item.id][0].response.data.url) || (resultData[item.id][0] && resultData[item.id][0].url)
          } else if ((item.type === "img" || item.type === "file") && !resultData[item.id].length) {
            resultData[item.id] = ''
          } else if (item.type === "date" && resultData[item.id]) {
            resultData[item.id] = resultData[item.id].format(item.format);
          }
        });
      }
    });

    return flag ? resultData : false;
  };
  getFieldValue = name => {
    let { getFieldValue } = this.props.form;
    return getFieldValue(name);
  };
  setFieldsValue = name => {
    let { setFieldsValue } = this.props.form;
    setFieldsValue({ [name]: "" });
  };
  normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  //上传文件只能有一个,所有如果多上传了要删除
  fileOnChange = ({ file, fileList }) => {
    if (file.status === "done") {
      if (file.response.code === 1) {
        if (fileList.length > 1) {
          fileList.shift();
        }
      } else {
        Modal.error({ content: file.response.msg });
        fileList.pop();
      }
    } else if (file.status === "error") {
      Modal.error({ content: "上传失败" });
      fileList.pop();
    }
  };
  renderSelect = element => {
    if (!element.hide) {
      //如果没有值就默认选中第一个
      // if(!element.initialValue && element.option.length){
      //   element.initialValue = element.option[0].code
      // }
      let { getFieldDecorator } = this.props.form;
      return (
        <React.Fragment key={element.id}>
          <FormItem
            {...formItemLayout}
            {...element.formItemLayout}
            label={element.label}
            style={{ marginBottom: "0.5rem" }}
          >
            {getFieldDecorator(element.id, {
              initialValue: element.initialValue,
              validateFirst: true,
              rules: element.rules
            })(
              <Select
                showSearch
                allowClear
                mode={element.type}
                disabled={!!element.disabled}
                onChange={!!element.onChange ? element.onChange : null}
                optionFilterProp={"children"}
              >
                {element.option.map(element => {
                  return (
                    <Option key={element.code} value={element.code}>
                      {element.name}
                    </Option>
                  );
                })}
              </Select>
            )}
          </FormItem>
          {element.hr ? <hr /> : null}
        </React.Fragment>
      );
    }
  };
  renderInput = element => {
    let rules = element.rules ? element.rules : [];
    if (!element.hide) {
      let getFieldDecorator = this.props.form.getFieldDecorator;
      return (
        <React.Fragment key={element.id}>
          <FormItem
            label={element.label}
            {...formItemLayout}
            {...element.formItemLayout}
            key={element.id}
            style={{ marginBottom: "0.5rem" }}
          >
            {getFieldDecorator(element.id, {
              initialValue: element.initialValue,
              validateFirst: true,
              rules: rules
            })(
              element.type === "text" ? (
                <Input disabled={element.disabled} />
              ) : (
                <TextArea disabled={element.disabled} rows={element.rows} />
              )
            )}
          </FormItem>
          {element.hr ? <hr /> : null}
        </React.Fragment>
      );
    }
  };
  renderPassWord = element => {
    let rules = element.rules ? element.rules : [];
    if (!element.hide) {
      let getFieldDecorator = this.props.form.getFieldDecorator;
      return (
        <React.Fragment key={element.id}>
          <FormItem
            label={element.label}
            {...formItemLayout}
            {...element.formItemLayout}
            key={element.id}
            style={{ marginBottom: "0.5rem" }}
          >
            {getFieldDecorator(element.id, {
              initialValue: element.initialValue,
              validateFirst: true,
              rules: rules
            })(
              <Input.Password
                visibilityToggle={false}
              />
            )}
          </FormItem>
        </React.Fragment>
      );
    }
  };
  renderCascader = element => {
    // if (!element.hide) {
    let getFieldDecorator = this.props.form.getFieldDecorator;
    return (
      <FormItem
        label={element.label}
        {...formItemLayout}
        {...element.formItemLayout}
        key={element.id}
        style={{ marginBottom: "0.5rem" }}
      >
        {getFieldDecorator(element.id, {
          initialValue: element.initialValue,
          validateFirst: true,
          rules: element.rules
        })(<Cascader options={element.option} />)}
      </FormItem>
    );
    // }
  };
  renderDate = element => {
    if (!element.hide) {
      let getFieldDecorator = this.props.form.getFieldDecorator;
      if (!!element.format) {
        element.format = element.format
          .replace("yyyy", "YYYY")
          .replace("dd", "DD");

      }
      if (!!element.initialValue) {
        element.initialValue = moment(element.initialValue, element.format);
      }

      return (
        <FormItem
          label={element.label}
          {...formItemLayout}
          {...element.formItemLayout}
          key={element.id}
          style={{ marginBottom: "0.5rem" }}
        >
          {getFieldDecorator(element.id, {
            initialValue: element.initialValue,
            validateFirst: true,
            rules: element.rules
          })(
            element.format == 'YYYY-MM' ?
              <MonthPicker format={element.format} />
              :
              <DatePicker
                onChange={element.change}
                showTime={element.format.includes("HH:mm:ss")}
                format={element.format}
              />
          )}
        </FormItem>
      );
    }
  };
  renderCheckBox = element => {
    // if (!element.hide) {
    let getFieldDecorator = this.props.form.getFieldDecorator;
    return (
      <FormItem
        label={element.label}
        {...formItemLayout}
        {...element.formItemLayout}
        key={element.id}
        style={{ marginBottom: "0.5rem" }}
      >
        {getFieldDecorator(element.id, {
          initialValue: element.initialValue,
          validateFirst: true,
          rules: element.rules
        })(
          <Checkbox.Group disabled={element.disabled} style={{ width: "100%" }}>
            <Row>
              {element.option.map(item =>
                item.code ? (
                  <Col
                    key={item.code}
                    span={item.span ? item.span : element.span}
                  >
                    <Checkbox value={item.code}>{item.name}</Checkbox>
                  </Col>
                ) : null
              )}
            </Row>
          </Checkbox.Group>
        )}
      </FormItem>
    );
    // }
  };
  renderCheckBoxItem = element => {
    // if (!element.hide) {
    let getFieldDecorator = this.props.form.getFieldDecorator;
    return (
      <FormItem
        label={element.label}
        {...formItemLayout}
        {...element.formItemLayout}
        key={element.id}
        style={{ marginBottom: "0.5rem" }}
      >
        {getFieldDecorator(element.id, {
          initialValue: element.initialValue,
          valuePropName: 'checked',
          rules: element.rules
        })(
          <Checkbox></Checkbox>
        )}
      </FormItem>
    );
  }
  renderRadio = element => {
    // if (!element.hide) {
    let getFieldDecorator = this.props.form.getFieldDecorator;
    return (
      <FormItem
        label={element.label}
        {...formItemLayout}
        {...element.formItemLayout}
        key={element.id}
        style={{ marginBottom: "0.5rem" }}
      >
        {getFieldDecorator(element.id, {
          initialValue: element.initialValue,
          validateFirst: true,
          rules: element.rules
        })(
          <Radio.Group
            onChange={!!element.onChange ? element.onChange : null}
            style={{ width: "100%" }}
          >
            <Row>
              {element.option.map(item => (
                <Col
                  key={item.code}
                  span={item.span ? item.span : element.span}
                >
                  <Radio value={item.code}>{item.name}</Radio>
                </Col>
              ))}
            </Row>
          </Radio.Group>
        )}
      </FormItem>
    );
    // }
  };
  renderTreeSelect = element => {
    let getFieldDecorator = this.props.form.getFieldDecorator;

    return (
      <React.Fragment key={element.id}>
        <FormItem
          label={element.label}
          {...formItemLayout}
          {...element.formItemLayout}
          key={element.id}
          style={{ marginBottom: "0.5rem" }}
        >
          {getFieldDecorator(element.id, {
            initialValue: element.initialValue,
            validateFirst: true,
            rules: element.rules
          })(
            <TreeSelect
              treeCheckable
              disabled={!!element.disabled}
              showSearch
              treeNodeFilterProp={"title"}
              dropdownStyle={{ maxHeight: 300, overflow: "auto", top: "100px" }}
              treeData={element.option}
              multiple={!!element.multiple}
            />
          )}
        </FormItem>
        {element.hr ? <hr /> : null}
      </React.Fragment>
    );
  };
  renderTree = element => {
    let getFieldDecorator = this.props.form.getFieldDecorator;
    let renderTreeNodes = data => {
      return data.map(item => {
        if (item.child) {
          return (
            <TreeNode title={item.name} key={item.orgcode} dataRef={item}>
              {renderTreeNodes(item.child)}
            </TreeNode>
          );
        }
        return <TreeNode {...item} />;
      });
    };
    return (
      <React.Fragment key={element.id}>
        <FormItem
          label={element.label}
          {...formItemLayout}
          {...element.formItemLayout}
          key={element.id}
          style={{ marginBottom: "0.5rem" }}
        >
          {getFieldDecorator(element.id, {
            initialValue: element.initialValue,
            validateFirst: true,
            valuePropName: "checkedKeys",
            trigger: "onCheck",
            rules: element.rules
          })(<Tree checkable>{renderTreeNodes(element.option)}</Tree>)}
        </FormItem>
      </React.Fragment>
    );
  };
  renderFile = (element, type = "imageFile") => {
    let getFieldDecorator = this.props.form.getFieldDecorator;
    return (
      <React.Fragment key={element.id}>
        <FormItem
          label={element.label}
          {...formItemLayout}
          {...element.formItemLayout}
          key={element.id}
          style={{ marginBottom: "0.5rem" }}
        >
          {getFieldDecorator(element.id, {
            initialValue: element.initialValue,
            validateFirst: true,
            getValueFromEvent: this.normFile,
            valuePropName: "fileList",
            rules: element.rules
          })(
            <Upload
              listType={type === "imageFile" ? "picture-card" : "text"}
              headers={{
                "Authorization": getToken()
              }}
              name={'file'}
              action={commonUrl.imgUp}
              onChange={this.fileOnChange}
            // onRemove={() => false}
            >
              <Button>
                <Icon type="upload" /> 上传文件
              </Button>
            </Upload>
          )}
        </FormItem>
      </React.Fragment>
    );
  };
  renderMyCustom = (element, type) => {
    let getFieldDecorator = this.props.form.getFieldDecorator;
    return (
      <React.Fragment key={element.id}>
        <FormItem
          label={element.label}
          {...formItemLayout}
          {...element.formItemLayout}
          key={element.id}
          style={{ marginBottom: "0.5rem" }}
        >
          {getFieldDecorator(element.id, {
            initialValue: element.initialValue,
            validateFirst: true,
            rules: element.rules
          })(<MyCustom element={element} component={element.component} />)}
        </FormItem>
      </React.Fragment>
    );
  };
  renderColor = element => {
    let getFieldDecorator = this.props.form.getFieldDecorator;
    return (
      <React.Fragment key={element.id}>
        <FormItem
          label={element.label}
          {...formItemLayout}
          {...element.formItemLayout}
          key={element.id}
          style={{ marginBottom: "0.5rem" }}
        >
          {getFieldDecorator(element.id, {
            initialValue: element.initialValue,
            validateFirst: true,
            rules: element.rules
          })(<SketchPicker />)}
        </FormItem>
      </React.Fragment>
    );
  };
  renderRichText = element => {
    let getFieldDecorator = this.props.form.getFieldDecorator;
    return (
      <React.Fragment key={element.id}>
        <FormItem
          label={element.label}
          {...formItemLayout}
          {...element.formItemLayout}
          key={element.id}
          style={{ marginBottom: "0.5rem" }}
        >
          {getFieldDecorator(element.id, {
            initialValue: element.initialValue,
            validateFirst: true,
            rules: element.rules
          })(<MyCustom component={RichText}></MyCustom>)
          }
        </FormItem>
      </React.Fragment>
    );
  }
  render() {
    let { data } = this.props;
    return (
      <React.Fragment>
        <Form>
          <Row gutter={24}>
            {data.map(element => {
              let formItem;
              switch (element.type) {
                case "select":
                  formItem = this.renderSelect(element);
                  break;
                case "textarea":
                  formItem = this.renderInput(element);
                  break;
                case "richText":
                  formItem = this.renderRichText(element);
                  break;
                case "cascader":
                  formItem = this.renderCascader(element);
                  break;
                case "date":
                  formItem = this.renderDate(element);
                  break;
                case "checkBox":
                  formItem = this.renderCheckBox(element);
                  break;
                case "checkBoxItem":
                  formItem = this.renderCheckBoxItem(element);
                  break;
                case "radio":
                  formItem = this.renderRadio(element);
                  break;
                case "img":
                  formItem = this.renderFile(element);
                  break;
                case "file":
                  formItem = this.renderFile(element, "docFile");
                  break;
                case "tags":
                  formItem = this.renderSelect(element);
                  break;
                case "multiple":
                  formItem = this.renderSelect(element);
                  break;
                case "text":
                  formItem = this.renderInput(element);
                  break;
                case "password":
                  formItem = this.renderPassWord(element);
                  break;
                case "treeSelect":
                  formItem = this.renderTreeSelect(element);
                  break;
                case "tree":
                  formItem = this.renderTree(element);
                  break;
                case "custom":
                  formItem = this.renderMyCustom(element);
                  break;
                case "color":
                  formItem = this.renderColor(element);
                  break;
                default:
                  break;
              }
              return (
                <Col
                  key={element.id}
                  span={element.col || 12}
                  style={{ minHeight: "2.5rem", display: `${element.show === false ? 'none' : ''}` }}
                >
                  {formItem}
                </Col>
              );
            })}
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

export default Form.create()(MyForm);