import React from "react";
import {
    Table, Modal, Button, Dropdown, Icon, Menu, Upload, Row, Col, message
} from "antd";
import TablePage from "../../components/TablePage";
import $http from "../../common/fetch";
import { userUrl } from '../../common/url'

let searchFormConfig = {
    form: [
        {
            title: '评论开始日期',
            id: 'begin',
            type: 'date',
            format: 'yyyy-MM-DD'
        },
        {
            title: '评论结束日期',
            id: 'end',
            type: 'date',
            format: 'yyyy-MM-DD'
        },
        {
            title: '用户昵称',
            id: 'name',
            type: 'text',
        },
        {
            title: '关键词',
            id: 'key',
            type: 'text',
        }
    ]
}

class UserComments extends React.Component {
    state = {

    }

    shield = (lineData) => {
        Modal.confirm({
            icon: <Icon type="warning" />,
            title: `你正在屏蔽 ${lineData.name} 用户的评论`,
            content: '屏蔽后将不在前端展示且无法恢复，你还要继续吗？',
            onOk: () => {
                $http.postData(userUrl.forbidden, { comment_id: lineData.id }).then(data => {
                    message.success('成功');
                    this.TablePage.getServerData();
                })
            }
        })
    }

    lookDetail = (lineData) => {
        Modal.info({
            width: 1000,
            title: `${lineData.name}/${lineData.created_at}的评论详情`,
            content: (
                <React.Fragment>
                    <Row gutter={24}>
                        <Col span={6}>发表地点：</Col>
                        <Col span={18}>{lineData.origin}</Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>发表时间：</Col>
                        <Col span={18}>{lineData.created_at}</Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>发表内容：</Col>
                        <Col span={18}>{lineData.content}</Col>
                    </Row>
                </React.Fragment>
            )
        })
    }
    
    render() {
        let authList = localStorage.getItem('zhiyao-auth-list') || [];
        let headColumns = [
            {
                title: '用户ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: '用户昵称',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '评论内容',
                dataIndex: 'content',
                key: 'content',
                render: (text, record) => {
                    return record.content.length > 10 ? record.content.slice(0, 10) + '...' : record.content
                }
            },
            {
                title: '评论时间',
                dataIndex: 'created_at',
                key: 'created_at',
            },
            {
                title: '操作',
                dataIndex: 'operation',
                render: (text, record) => {
                    return (
                        <React.Fragment>
                            {
                                authList.includes('comment.forbidden') &&
                                (record.status == 1 ?
                                    <Button onClick={() => { this.shield(record)}}>屏蔽</Button>
                                    :
                                    null)
                            }
                            <Button style={{ marginLeft: '10px' }} onClick={() => { this.lookDetail(record)}}>查看详情</Button>
                        </React.Fragment>
                    )
                }
            }
        ]
        return (
            <div className="page-user-list">
                <TablePage
                    ref={node => (this.TablePage = node)}
                    pageLine={['用户管理', '用户评论']}
                    headColumns={headColumns}
                    loadStore={userUrl.commentSearch }
                    searchFormConfig={searchFormConfig}
                ></TablePage>
            </div>
        );
    }
}

export default UserComments;
