import React from "react";
import { Table, Modal, Button, Dropdown, Icon, Menu, Upload, Breadcrumb } from "antd";
import MyModal from "../MyModal";
import $http from "../../common/fetch";
import SearchFrom from '../SearchForm'
import "./index.css";



// 导出基础table页面
class TablePage extends React.Component {
  state = {
    // 查询参数
    searchParams: {},
    // 新增编辑弹框展示
    modalShow: false,
    // 弹框配置
    modalConfig: {},
    // 分页
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0
    },
    // 数据源
    dataSource: [],
  }
  // 从服务器获取数据
  getServerData = async () => {
    let { searchParams, pagination } = this.state;
    let { loadStore } = this.props;
    let params = {
      ...searchParams,
      ...pagination,
      page_num: pagination.current,
      page_size: pagination.pageSize
    };
    if (typeof loadStore === 'object') {
      this.setState({
        dataSource: loadStore
      })
    } else {
      $http.postData(loadStore, params).then(dataSource => {
        this.setState({
          dataSource: dataSource.data.list,
          pagination: {
            ...pagination,
            total: dataSource.data.total
          }
        })
      })

    }

  };
  componentWillMount() {
    this.getServerData();
  }
  // 查询数据变化的回调
  updateSearchParams = (searchParams) => {
    for (const key in searchParams) {
      if (/^\d+$/.test(searchParams[key])){
        searchParams[key] = Number(searchParams[key])
      }
    }
    this.setState({ searchParams });
  }

  // 表格排序、翻页等功能时的回调
  onTableStateChange = (pagination, filters, sorter) => {
    this.setState({
      pagination
    }, this.getServerData);
  };
  // 弹框编辑
  modalEdit = (modalConfig) => {
    this.setState({
      modalConfig,
      modalShow: true
    })
  }
  // 弹框确定事件
  handleOk = (data) => {
    let { handleOk = () => { } } = this.props;

    handleOk(data, { close: this.cancel })
  }
  cancel = () => {
    this.setState({
      modalShow: false
    })
  }
  // 点击查询
  search = () => {
    let pagination = {
      ...this.state.pagination,
      current: 1
    };

    this.setState(
      {
        pagination
      },
      () => {
        this.getServerData();
      }
    );
  }

  render() {
    let { pagination, modalShow, modalConfig, dataSource = [] } = this.state;
    let { pageLine = [], searchFormConfig, headColumns, activeList } = this.props;
    let authList = localStorage.getItem('zhiyao-auth-list') || [];
    return (
      <div className="wrapper-main-body">
        <Breadcrumb style={{height: '54px',display: 'flex',alignItems:'center'}}>
          {
            pageLine.map((item) => {
              return (
                <Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>
              )
            })
          }
        </Breadcrumb>
        <div className="page-title">{pageLine[pageLine.length - 1]}</div>
        <div className="page-search">
          <SearchFrom
            configData={searchFormConfig}
            updateSearchParams={this.updateSearchParams}
            searchTableAction={this.search}
          ></SearchFrom>
        </div>
        <div className="page-active-box">
          {
            activeList ? activeList.map((item, index) => {
              return (
                authList.includes(item.auth) ?
                  <Button key={index} type={item.class} onClick={item.click} >{item.title}</Button> : null
              )
            }) : null
          }
        </div>
        <Table
          rowKey="id"
          pagination={{ ...pagination, showQuickJumper: true}}
          onChange={this.onTableStateChange}
          dataSource={dataSource}
          columns={headColumns} />
        {
          modalShow ? <MyModal
            ref={node => (this.MyModal = node)}
            modalConfig={modalConfig}
            visible={modalShow}
            handleOk={this.handleOk}
            cancel={this.cancel}
          /> : null
        }
      </div>
    );
  }
}

export default TablePage;
