import React from "react";
import { Table, Modal, Button, Dropdown, Icon, Menu, Upload, Input, message } from "antd";
import TablePage from "../../components/TablePage";
import $http from "../../common/fetch";
import { roleUrl } from '../../common/url'

let searchFormConfig = {
    form: [
        {
            title: '角色名称',
            id: 'name',
            type: 'text',
        }
    ]
}

class Role extends React.Component {
    state = {
        handleOk: (...res) => {
            this.saveSendMessage(...res)
        }
    }
    async componentWillMount() {
        let authList = await $http.postData(roleUrl.authList);
        this.setState({
            authList: authList.data
        })
    }
    changeStatus = (lineData) => {
        new Promise((resovle) => {
            if (lineData.status === 1) {
                Modal.confirm({
                    icon: <Icon type="warning" />,
                    title: `你正在禁用 ${lineData.id} 用户`,
                    content: '禁用后该用户将无法登录，你还要继续吗？',
                    onOk: () => {
                        resovle(2)
                    }
                })
            } else {
                resovle(1)
            }
        }).then((status) => {
            $http.postData(roleUrl.toggle, { id: lineData.id, status }).then(data => {
                message.success('成功');
                this.TablePage.getServerData();
            })
        })

    }
    add = () => {
        this.TablePage.modalEdit(
            this.initSendMessageConfig({})
        );
    }
    edit = (lineData) => {
        this.TablePage.modalEdit(
            this.initSendMessageConfig(lineData)
        );
    }
    saveSendMessage = (params, model) => {
        if (params){
            model.close();
            $http.postData(roleUrl.save, params).then(data => {
                message.success('成功');
                this.TablePage.getServerData();
            })
        }
    }
    initTree(data) {
        let TreeData = []
        data.forEach((item, index) => {
            let someOne = {
                value: `${item.id}-${index}`,
                key: `${item.id}-${index}`,
                title: item.name,
                children: []
            }
            item.items.forEach(ele => {
                someOne.children.push({
                    value: ele.id,
                    key: ele.id,
                    title: ele.name,
                })
            })
            TreeData.push(someOne)
        });
        return TreeData
    }
    initSendMessageConfig = (lineData) => {
        let { authList } = this.state;
        authList = this.initTree(authList)
        return {
            title: lineData.id ? '编辑' : "新增",
            width: 800,
            data: [
                {
                    show: false,
                    col: 24,
                    id: 'id',
                    label: 'id',
                    type: 'text',
                    initialValue: lineData.id
                },
                {
                    col: 24,
                    id: 'name',
                    label: '角色名称',
                    rules: [{ required: true, message: '必填' }],
                    type: 'text',
                    initialValue: lineData.name
                },
                {
                    col: 24,
                    id: 'privileges',
                    label: '权限',
                    rules: [{ required: true, message: '必填' }],
                    type: 'treeSelect',
                    multiple: true,
                    initialValue: lineData.privileges,
                    option: authList
                }
            ]
        }
    }
    render() {
        let authList = localStorage.getItem('zhiyao-auth-list') || [];
        let { handleOk } = this.state;
        let headColumns = [
            {
                title: '角色编号',
                dataIndex: 'number',
                key: 'number',
            },
            {
                title: '角色名称',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (text, record) => {
                    return record.status === 1 ? '启用' : '禁用'
                }
            },
            {
                title: '操作',
                dataIndex: 'operation',
                render: (text, record) => {
                    return (
                        <React.Fragment>
                            {authList.includes('role.toggle') &&
                                (record.status === 1 ?
                                    <Button onClick={() => {
                                        this.changeStatus(record)
                                    }}>禁用</Button>
                                    :
                                    <Button onClick={() => {
                                        this.changeStatus(record)
                                    }}>启用</Button>)
                            }
                            {authList.includes('role.save') && <Button style={{ marginLeft: '10px' }} onClick={() => {
                                this.edit(record)
                            }}>修改</Button>}
                            
                        </React.Fragment>
                    )
                }
            }
        ]
        let activeList = [
            {
                auth: 'role.save',
                title: '新增',
                click: this.add
            }
        ]
        return (
            <div className="page-role">
                <TablePage
                    ref={node => (this.TablePage = node)}
                    pageLine={['权限管理', '角色']}
                    headColumns={headColumns}
                    activeList={activeList}
                    handleOk={handleOk.bind(this)}
                    loadStore={roleUrl.list}
                    searchFormConfig={searchFormConfig}
                ></TablePage>
            </div>
        );
    }
}

export default Role;
