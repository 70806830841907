import React from "react";
import { Table, Modal, Button, Dropdown, Icon, Menu, Upload, Input, message } from "antd";
import TablePage from "../../components/TablePage";
import $http from "../../common/fetch";
import { memberUrl, roleUrl } from '../../common/url'

let searchFormConfig = {
    form: [
        {
            title: '用户名称',
            id: 'name',
            type: 'text',
        }
    ]
}

class User extends React.Component {
    state = {
        handleOk: (...res) => {
            this.saveSendMessage(...res)
        },
        roleList: []
    }
    async componentWillMount() {
        let roleList = await $http.postData(memberUrl.getRoleList);
       
        this.setState({
            roleList: roleList.data.map(item => {
                return {
                    code: item.id,
                    name: item.name
                }
            })
        })
    }
    changeStatus = (lineData) => {
        new Promise((resovle) => {
            if (lineData.status === 1) {
                Modal.confirm({
                    icon: <Icon type="warning" />,
                    title: `你正在禁用 ${lineData.id} 用户`,
                    content: '禁用后该用户将无法登录，你还要继续吗？',
                    onOk: () => {
                        resovle(2)
                    }
                })
            } else {
                resovle(1)
            }
        }).then((status) => {
            $http.postData(memberUrl.toggle, { id: lineData.id, status }).then(data => {
                message.success('成功');
                this.TablePage.getServerData();
            })
        })

    }
    add = () => {
        this.TablePage.modalEdit(
            this.initSendMessageConfig({})
        );
    }
    edit = (lineData) => {
        this.TablePage.modalEdit(
            this.initSendMessageConfig(lineData)
        );
    }
    saveSendMessage = (params, model) => {
        model.close();
        $http.postData(memberUrl.save, params).then(data => {
            message.success('成功');
            this.TablePage.getServerData();
        })
    }

    initSendMessageConfig = (lineData) => {
        let { roleList } = this.state
        return {
            title: lineData.id ? '编辑' : "新增",
            width: 800,
            data: [
                {
                    show: false,
                    col: 24,
                    id: 'id',
                    label: 'id',
                    type: 'text',
                    initialValue: lineData.id || 0
                },
                {
                    col: 24,
                    id: 'name',
                    label: '用户名称',
                    type: 'text',
                    initialValue: lineData.name
                },
                {
                    col: 24,
                    id: 'phone',
                    label: '账户（手机号）',
                    type: 'text',
                    initialValue: lineData.account
                },
                {
                    col: 24,
                    id: 'role_ids',
                    label: '角色',
                    type: 'multiple',
                    option: roleList,
                    initialValue: (lineData.roles || []).map(item => {
                        return item.id
                    }),
                }
            ]
        }
    }
    render() {
        let authList = localStorage.getItem('zhiyao-auth-list') || [];
        let { handleOk } = this.state;
        let headColumns = [
            {
                title: '用户编号',
                dataIndex: 'number',
                key: 'number',
            },
            {
                title: '用户名称',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '用户账号',
                dataIndex: 'account',
                key: 'account',
            },
            {
                title: '角色',
                dataIndex: 'roles',
                key: 'roles',
                render: (text, record) => {
                    return (record.roles || []).map(item => item.name).join(',')
                }
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (text, record) => {
                    return record.status === 1 ? '启用' : '禁用'
                }
            },
            {
                title: '操作',
                dataIndex: 'operation',
                render: (text, record) => {
                    return (
                        <React.Fragment>
                            {authList.includes('member.toggle') &&
                                (record.status === 1 ?
                                    <Button onClick={() => {
                                        this.changeStatus(record)
                                    }}>禁用</Button>
                                    :
                                    <Button onClick={() => {
                                        this.changeStatus(record)
                                    }}>启用</Button>)
                            }
                            {authList.includes('member.save') && <Button style={{ marginLeft: '10px' }} onClick={() => {
                                this.edit(record)
                            }}>修改</Button>}
                        </React.Fragment>
                    )
                }
            }
        ]
        let activeList = [
            {
                auth: 'member.save',
                title: '新增',
                click: this.add
            }
        ]
        return (
            <div className="page-user">
                <TablePage
                    ref={node => (this.TablePage = node)}
                    pageLine={['权限管理', '用户']}
                    headColumns={headColumns}
                    activeList={activeList}
                    handleOk={handleOk.bind(this)}
                    loadStore={memberUrl.list}
                    searchFormConfig={searchFormConfig}
                ></TablePage>
            </div>
        );
    }
}

export default User;
