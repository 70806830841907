import { Element } from 'slate'
import $ from '../utils/dom'

export function textStyleToHtml(node, elemHtml) {
    if (!Element.isElement(node)) return elemHtml

    const { LetterSpace } = node
    if (!LetterSpace) return elemHtml

    // 设置样式
    const $elem = $(elemHtml)
    $elem.css('letter-spacing', LetterSpace)

    // 输出 html
    const $div = $(`<div></div>`)
    $div.append($elem)
    return $div.html()
}