import { Editor, Node, Element, Transforms } from 'slate'
import { ISelectMenu, IDomEditor, DomEditor, IOption, t } from '@wangeditor/core'
import { genLetterSpaceConfig } from './config'

class LetterSpaceMenu {
    title = t('LetterSpace.title')
    tag = 'select'
    width = 80

    getOptions(editor) {
        const options = []

        // 获取配置，参考 './config.ts'
        const LetterSpaceList = genLetterSpaceConfig()

        // 生成 options
        options.push({
            text: t('0'),
            value: '', // this.getValue(editor) 未找到结果时，会返回 '' ，正好对应到这里
        })
        LetterSpaceList.forEach((height) => {
            options.push({
                text: height,
                value: height,
            })
        })

        // 设置 selected
        const curValue = this.getValue(editor)
        options.forEach(opt => {
            if (opt.value === curValue) {
                opt.selected = true
            } else {
                delete opt.selected
            }
        })

        return options
    }

    /**
     * 获取匹配的 node 节点
     * @param editor editor
     */
    getMatchNode(editor) {
        const [nodeEntry] = Editor.nodes(editor, {
            match: n => {
                const type = DomEditor.getNodeType(n)

                // line-height 匹配如下类型的 node
                if (type.startsWith('header')) return true
                if (['paragraph', 'blockquote', 'bulleted-list', 'numbered-list'].includes(type)) {
                    return true
                }

                return false
            },
            universal: true,
            mode: 'highest', // 匹配最高层级
        })

        if (nodeEntry == null) return null
        return nodeEntry[0]
    }

    isActive(editor) {
        // select menu 会显示 selected value ，用不到 active
        return false
    }

    /**
     * 获取 node.LetterSpace 的值（如 '1' '1.5'），没有则返回 ''
     * @param editor editor
     */
    getValue(editor) {
        const node = this.getMatchNode(editor)
        if (node == null) return ''
        if (!Element.isElement(node)) return ''

        return (node).LetterSpace || ''
    }

    isDisabled(editor) {
        if (editor.selection == null) return true // 禁用

        const node = this.getMatchNode(editor)
        if (node == null) return true // 未匹配到指定 node ，禁用

        return false
    }

    exec(editor, value) {
        Transforms.setNodes(
            editor,
            {
                LetterSpace: value.toString(),
            },
            { mode: 'highest' }
        )
    }
}

export default LetterSpaceMenu