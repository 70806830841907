import { Boot } from '@wangeditor/editor'
import LetterSpace from './letterSpace'

function registerModule(module) {
    const {
        menus,
        renderTextStyle,
        textStyleToHtml,
    } = module

    if (menus) {
        menus.forEach(menu => Boot.registerMenu(menu))
    }
   
    if (renderTextStyle) {
        Boot.registerRenderTextStyle(renderTextStyle)
    }
  
    if (textStyleToHtml) {
        Boot.registerTextStyleToHtml(textStyleToHtml)
    }
   
}

registerModule(LetterSpace);








// 定义菜单配置
// export const menuConf = {
//     key: 'letterSpace', // menu key ，唯一。注册之后，可配置到工具栏
//     factory() {

//         return 
//     },
// }




// 注册到 wangEditor
Boot.registerMenu(LetterSpace)